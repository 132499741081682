.ring {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed #bf8387;
  border-radius: 100%;
}

/* =Animate the stuff
------------------------ */

.load .ring {
  animation: loading 2.8s 0s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
