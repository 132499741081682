.recharts-default-legend {
  margin-top: -80px !important;
}
._report-chart-container {
  margin-left: -40px;
}

input[type="date"] {
  /* background: url("./Assets/calendar.svg") 100% center no-repeat !important; */
}
