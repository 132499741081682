@font-face {
  font-family: "CircularStdBlack";
  src: url("./assets/fonts/CircularStd-Black.woff2") format("woff2"),
    url("./assets/fonts/CircularStd-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CircularStdBold";
  src: url("./assets/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("./assets/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CircularStdMedium";
  src: url("./assets/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("./assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CircularStdBook";
  src: url("./assets/fonts/CircularStd-Book.woff2") format("woff2"),
    url("./assets/fonts/CircularStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?5kiojs");
  src: url("./assets/fonts/icomoon.eot?5kiojs#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?5kiojs") format("truetype"),
    url("./assets/fonts/icomoon.woff?5kiojs") format("woff"),
    url("./assets/fonts/icomoon.svg?5kiojs#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
